<template>
    <modal ref="modalCrearNew" :titulo="`${titleAdd}`" adicional="Guardar" @adicional="add">
        <ValidationObserver ref="createNew">
            <div class="row m-3 f-15">
                <div class="col-12">
                    <p class="input-label-top">Nombre</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="generalNewRuta.nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                    <br/>
                    <p class="input-label-top">{{this.type == 2 ? "Descripción" : "Nombre de ruta"}}</p>
                    <ValidationProvider v-slot="{errors}" name="descripcion" rules="required">
                        <el-input v-model="generalNewRuta.descripcion" type="textarea" :autosize="{ minRows: 3 }" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {  mapActions } from 'vuex'
export default {
    props: {
        type: {
            type: Number,
            default: null
        },
    },
    data(){
        return {
            generalNewRuta: {
                nombre: '',
                descripcion: ''
            },
        }
    },
    computed: {
        titleAdd() {
            return this.type == 2 ? "Añadir Permiso" : "Añadir Ruta"
        },
        idMenu(){ 
            return this.$route.params.tab
        }
    },
    methods: {
        ...mapActions({
            Action_create_ruta_permiso : 'configurar/permisos/menus/Action_create_ruta_permiso'
        }),
        toggle(){
            this.$refs.createNew.reset()
            this.$refs.modalCrearNew.toggle()
        },
        async add(){
            const valid = await this.$refs.createNew.validate()
            if (valid) {
                this.generalNewRuta.id_menu = +this.idMenu;
                this.generalNewRuta.tipo = this.type;
                this.generalNewRuta.estado = 1;
                this.generalNewRuta.ruta = "/"+this.generalNewRuta.nombre;
                const nombreRuta = this.generalNewRuta.nombre.replace(/ /g, ".")
                this.generalNewRuta.name_ruta = nombreRuta.toLowerCase();
                const newHeaders_total = await this.Action_create_ruta_permiso(this.generalNewRuta);
                this.$emit('newHeaders', newHeaders_total);
                this.$refs.modalCrearNew.toggle()
            }
            this.clear()
        },
        clear(){
            this.generalNewRuta = {
                nombre: '',
                descripcion: ''
            }
        }
    }
}
</script>